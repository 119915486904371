import React, { useState, useMemo } from 'react';
import { formatCurrency } from '../../utils/chartUtils';
import './TopRevenueIds.css';

function TopRevenueIds({ allVaults, currentPage }) {
  const [selectedChain, setSelectedChain] = useState('All');
  const [selectedPlatform, setSelectedPlatform] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [sortColumn, setSortColumn] = useState('revenue');
  const [sortDirection, setSortDirection] = useState('desc');

  const chains = useMemo(() => {
    const chainSet = new Set(allVaults.map(vault => vault.chain));
    return ['All', ...Array.from(chainSet).sort()];
  }, [allVaults]);

  const platforms = useMemo(() => {
    const platformSet = new Set(allVaults.map(vault => vault.platform));
    return ['All', ...Array.from(platformSet).sort()];
  }, [allVaults]);

  const types = useMemo(() => {
    const typeSet = new Set(allVaults.map(vault => vault.type || 'Unknown'));
    return ['All', ...Array.from(typeSet).sort()];
  }, [allVaults]);

  const formatTokens = id => {
    const trimmedId = id.replace(/-prod$/, '');
    const parts = trimmedId.split('-');
    if (parts.length >= 2) {
      const lastTwoParts = parts.slice(-2).map(part => part.toUpperCase());
      return lastTwoParts.join('-');
    }
    return trimmedId.toUpperCase();
  };

  const filteredTopIds = useMemo(() => {
    let filtered = allVaults;

    if (selectedChain !== 'All') {
      filtered = filtered.filter(vault => vault.chain === selectedChain);
    }

    if (selectedPlatform !== 'All') {
      filtered = filtered.filter(vault => vault.platform === selectedPlatform);
    }

    if (selectedType !== 'All') {
      filtered = filtered.filter(vault => vault.type === selectedType);
    }

    return filtered
      .sort((a, b) => {
        return sortDirection === 'asc'
          ? a[sortColumn] - b[sortColumn]
          : b[sortColumn] - a[sortColumn];
      })
      .slice(0, 10)
      .map((vault, index) => ({
        ...vault,
        tokens: formatTokens(vault.id),
        rank: index + 1,
      }));
  }, [selectedChain, selectedPlatform, selectedType, allVaults, sortColumn, sortDirection]);

  const handleSort = column => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  const handleExport = () => {
    const headers = [
      'Rank',
      'Tokens',
      'Platform',
      'Blockchain',
      'Type',
      'TVL',
      'Annualized Revenue',
    ];
    const csvContent = [
      headers.join(','),
      ...allVaults.map((item, index) =>
        [
          index + 1,
          formatTokens(item.id),
          item.platform,
          item.chain,
          item.type,
          item.tvl,
          item.revenue,
        ].join(',')
      ),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'top_revenue_ids.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="top-revenue-ids">
      <div className="top-revenue-header">
        <h2>Top Revenue Producing</h2>
        <div className="filter-controls">
          <div className="filter-group">
            <label htmlFor="chain-select">Blockchain:</label>
            <select
              id="chain-select"
              value={selectedChain}
              onChange={e => setSelectedChain(e.target.value)}
              className="chain-select"
            >
              {chains.map(chain => (
                <option key={chain} value={chain}>
                  {chain}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-group">
            <label htmlFor="platform-select">Platform:</label>
            <select
              id="platform-select"
              value={selectedPlatform}
              onChange={e => setSelectedPlatform(e.target.value)}
              className="platform-select"
            >
              {platforms.map(platform => (
                <option key={platform} value={platform}>
                  {platform}
                </option>
              ))}
            </select>
          </div>
          {currentPage === 'overview' && (
            <div className="filter-group">
              <label htmlFor="type-select">Type:</label>
              <select
                id="type-select"
                value={selectedType}
                onChange={e => setSelectedType(e.target.value)}
                className="type-select"
              >
                {types.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          )}
          <button className="export-button" onClick={handleExport}>
            Export Data
          </button>
        </div>
      </div>
      <div className="id-list-container">
        <table className="id-list-table">
          <thead>
            <tr>
              <th className="id-rank-header">#</th>
              <th className="id-tokens-header">Tokens</th>
              <th className="id-platform-header">Platform</th>
              <th className="id-chain-header">Blockchain</th>
              <th className="id-type-header">Type</th>
              <th
                className={`id-tvl-header sortable ${sortColumn === 'tvl' ? sortDirection : ''}`}
                onClick={() => handleSort('tvl')}
              >
                TVL
              </th>
              <th
                className={`id-revenue-header sortable ${sortColumn === 'revenue' ? sortDirection : ''}`}
                onClick={() => handleSort('revenue')}
              >
                Annualized Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredTopIds.map(item => (
              <tr key={item.id} className="id-item">
                <td className="id-rank">{item.rank}</td>
                <td className="id-tokens">
                  <a
                    href={`https://app.beefy.com/vault/${item.id}${item.type === 'CLM' ? '-rp' : ''}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.tokens}
                  </a>
                </td>
                <td className="id-platform">{item.platform}</td>
                <td className="id-chain">{item.chain}</td>
                <td className="id-type">{item.type}</td>
                <td className="id-tvl">{formatCurrency(item.tvl)}</td>
                <td className="id-revenue">{formatCurrency(item.revenue)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TopRevenueIds;
