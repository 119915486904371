import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { formatCurrency } from '../../utils/chartUtils';
import '../Chart/Chart.css';
import './HistoricalTVLChart.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function HistoricalTVLChart({ historicalData, colorMap }) {
  const [selectedChain, setSelectedChain] = useState('all');
  const [selectedType, setSelectedType] = useState('total');
  const [chartData, setChartData] = useState(null);
  const [chains, setChains] = useState([]);
  const [availableTypes, setAvailableTypes] = useState(['total', 'clm', 'vault']);
  const [isExpanded, setIsExpanded] = useState(false);

  const filterLastYearData = (data) => {
    const oneYearAgo = Date.now() - 365 * 24 * 60 * 60 * 1000;
    return data.filter(item => item.t * 1000 >= oneYearAgo);
  };

  useEffect(() => {
    if (historicalData) {
      const availableChains = Object.keys(historicalData).sort();
      setChains(availableChains);
      setSelectedChain('all'); // Set default to 'all'
    }
  }, [historicalData]);

  useEffect(() => {
    if (historicalData && selectedChain) {
      let data = selectedChain === 'all' 
        ? combineAllChainsData(historicalData) 
        : historicalData[selectedChain];

      if (Array.isArray(data)) {
        data = filterLastYearData(data);
        const cleanedData = removeOutliers(data);
        const sortedData = cleanedData.sort((a, b) => a.t - b.t);

        // Filter out timestamps with 0 TVL for the selected type
        const filteredData = sortedData.filter(item => item[selectedType] > 0);

        const labels = filteredData.map(item => formatDate(item.t));

        const types = ['total', 'clm', 'vault'].filter(type => 
          filteredData.some(item => item[type] && item[type] > 0)
        );
        setAvailableTypes(types);

        if (!types.includes(selectedType)) {
          setSelectedType(types[0] || 'total');
        }

        const selectedTypeValues = filteredData.map(item => item[selectedType] || 0);

        setChartData({
          labels,
          datasets: [
            {
              label: `${selectedChain === 'all' ? 'All Chains' : selectedChain} - ${selectedType}`,
              data: selectedTypeValues,
              borderColor: colorMap[selectedChain] || '#4ecdc4',
              backgroundColor: 'rgba(78, 205, 196, 0.1)',
              borderWidth: 2,
              fill: true,
              tension: 0.1,
              pointRadius: 0,
            },
          ],
        });
      } else {
        console.error('Invalid data format for historical TVL');
        setChartData(null);
      }
    }
  }, [historicalData, selectedChain, selectedType, colorMap]);

  const combineAllChainsData = (data) => {
    const combinedData = {};
    Object.entries(data).forEach(([chain, chainData]) => {
      if (chain.toLowerCase() !== 'total defi') {
        const lastYearData = filterLastYearData(chainData);
        lastYearData.forEach(item => {
          if (!combinedData[item.t]) {
            combinedData[item.t] = { t: item.t, total: 0, clm: 0, vault: 0 };
          }
          combinedData[item.t].total += item.total || 0;
          combinedData[item.t].clm += item.clm || 0;
          combinedData[item.t].vault += item.vault || 0;
        });
      }
    });
    return Object.values(combinedData);
  };

  const removeOutliers = (data) => {
    const values = data.map(item => item.total);
    const sortedValues = values.slice().sort((a, b) => a - b);
    const q1 = sortedValues[Math.floor(sortedValues.length / 4)];
    const q3 = sortedValues[Math.ceil(sortedValues.length * 3 / 4)];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return data.filter(item => item.total >= lowerBound && item.total <= upperBound);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('default', { month: 'short', year: 'numeric' });
  };

  const handleChainChange = (e) => {
    setSelectedChain(e.target.value);
    setSelectedType('total'); // Reset type when chain changes
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleChartClick = (event) => {
    // Only expand if the click is on the chart area, not on the filters
    if (!event.target.closest('.chain-selector-wrapper') && !event.target.closest('.type-selector-wrapper')) {
      setIsExpanded(true);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsExpanded(false);
    }
  };

  const renderChainSelector = () => (
    <div className="chain-selector-wrapper">
      <select className="chain-selector" value={selectedChain} onChange={handleChainChange}>
        <option value="all">All Chains</option>
        <option value="Total DeFi">Total DeFi</option>
        {chains.filter(chain => chain.toLowerCase() !== 'total defi').map((chain) => (
          <option key={chain} value={chain}>{chain}</option>
        ))}
      </select>
    </div>
  );

  const renderTypeSelector = () => (
    <div className="type-selector-wrapper">
      <select className="type-selector" value={selectedType} onChange={handleTypeChange}>
        {availableTypes.map((type) => (
          <option key={type} value={type}>
            {type === 'clm' ? 'CLM' : type.charAt(0).toUpperCase() + type.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Month',
          color: '#e0e0e0', // Match the font color
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          autoSkip: true,
          maxTicksLimit: 12,
          color: '#e0e0e0', // Match the font color
        },
        grid: {
            display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'TVL',
          color: '#e0e0e0', // Match the font color
        },
        ticks: {
          callback: (value) => formatCurrency(value),
          color: '#e0e0e0', // Match the font color
        },
        grid: {
            display: false,
        },
      }
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: (context) => context[0].label,
          label: (context) => `Total TVL: ${formatCurrency(context.parsed.y)}`
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Semi-transparent black background
        titleColor: '#e0e0e0', // Match the font color
        bodyColor: '#e0e0e0', // Match the font color
      },
      legend: {
        display: false, // Set this to false to remove the legend
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    onClick: null, // Remove the onClick handler from chart options
  };

  if (!historicalData) {
    return <div className="chart">
      <div className="error-text">No historical data available</div>
    </div>;
  }

  return (
    <>
      <div className={`chart ${isExpanded ? 'chart-expanded' : ''}`} onClick={handleChartClick}>
        <div className="chart-header">
          <h2>Annual TVL Trend</h2>
          <div className="chart-filters">
            {renderChainSelector()}
            {renderTypeSelector()}
          </div>
        </div>
        <div className="chart-wrapper">
          {chartData && <Line data={chartData} options={options} />}
        </div>
      </div>
      {isExpanded && (
        <div className="chart-overlay" onClick={handleOverlayClick}>
          <div className="chart-expanded" onClick={(e) => e.stopPropagation()}>
            <div className="chart-header">
              <h2>Annual TVL Trend</h2>
              <div className="chart-filters">
                {renderChainSelector()}
                {renderTypeSelector()}
              </div>
            </div>
            <div className="chart-wrapper">
              {chartData && <Line data={chartData} options={options} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HistoricalTVLChart;
