import React from 'react';
import ChartComponent from '../Chart/Chart';
import {
  formatCurrency,
  formatNumber,
  chartColors,
  verticalChartOptions,
  horizontalChartOptions,
  isMobile, // Import the utility function
} from '../../utils/chartUtils';
import '../ChartContainer.css';

function CLMsChartContainer({
  clmData,
  chainTVL,
  platformTVL,
  almTVLData,
  revenueByChain,
  revenueByPlatform,
  colorMap,
  isLoading,
  usersByChainAndType,
  usersByPlatformAndType,
}) {
  const createChartData = (data, colors, isCurrency = true, aggregateThreshold = 0) => {
    if (!data || typeof data !== 'object') {
      console.log('Invalid data for createChartData:', data);
      return { labels: [], datasets: [] };
    }

    let sortedEntries = Object.entries(data).sort((a, b) => b[1] - a[1]);

    if (aggregateThreshold > 0) {
      let otherSum = 0;
      sortedEntries = sortedEntries.reduce((acc, [key, value]) => {
        if (value < aggregateThreshold) {
          otherSum += value;
        } else {
          acc.push([key, value]);
        }
        return acc;
      }, []);

      if (otherSum > 0) {
        sortedEntries.push(['Other', otherSum]);
      }

      sortedEntries.sort((a, b) => b[1] - a[1]);
    }

    const sortedLabels = sortedEntries.map(
      ([key]) => key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
    );
    const sortedData = sortedEntries.map(([, value]) => value);

    return {
      labels: sortedLabels,
      datasets: [
        {
          label: isCurrency ? 'Value' : 'Count',
          data: sortedData,
          backgroundColor: sortedLabels.map(
            key => colors[key] || chartColors[sortedLabels.indexOf(key) % chartColors.length]
          ),
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderWidth: 1,
        },
      ],
    };
  };

  const clmOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const tvlOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.y.ticks,
          callback: value => formatCurrency(value),
        },
      },
    },
    plugins: {
      ...verticalChartOptions.plugins,
      tooltip: {
        ...verticalChartOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const value = context.parsed.y;
            return `${formatCurrency(value)}`;
          },
        },
      },
    },
  };

  const revenueOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.y.ticks,
          callback: value => formatCurrency(value),
        },
      },
    },
    plugins: {
      ...verticalChartOptions.plugins,
      tooltip: {
        ...verticalChartOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const value = context.parsed.y;
            return `${formatCurrency(value)}`;
          },
        },
      },
    },
  };

  const usersOptions = isMobile() ? horizontalChartOptions : {
    ...verticalChartOptions,
    scales: {
      ...verticalChartOptions.scales,
      y: {
        ...verticalChartOptions.scales.y,
        ticks: {
          ...verticalChartOptions.scales.y.ticks,
          callback: value => formatNumber(value),
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      ...verticalChartOptions.plugins,
      tooltip: {
        ...verticalChartOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const userCount = context.parsed.y;
            return `${formatNumber(userCount)} Users`;
          },
        },
      },
    },
  };

  const createUsersByChainData = () => {
    if (!usersByChainAndType) return { labels: [], datasets: [] };
    const clmUsers = {};
    Object.entries(usersByChainAndType).forEach(([chain, types]) => {
      clmUsers[chain] = (types.clm || 0);
    });
    return createChartData(clmUsers, colorMap, false, 1);
  };

  const createUsersByPlatformData = () => {
    if (!usersByPlatformAndType) return { labels: [], datasets: [] };
    const clmUsers = {};
    Object.entries(usersByPlatformAndType).forEach(([platform, types]) => {
      clmUsers[platform] = (types.clm || 0);
    });
    return createChartData(clmUsers, colorMap, false, 1);
  };

  return (
    <div className="charts-container">
      {!isLoading && (
        <>
          <ChartComponent
            title="CLMs per Chain"
            data={createChartData(clmData, colorMap, false)}
            options={clmOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="TVL per Chain"
            data={createChartData(chainTVL, colorMap)}
            options={tvlOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="TVL per Platform"
            data={createChartData(platformTVL, colorMap)}
            options={tvlOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="ALM TVL Comparison"
            data={createChartData(
              Object.fromEntries(
                Object.entries(almTVLData).map(([key, value]) => [value.label, value.tvl])
              ),
              colorMap
            )}
            options={tvlOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="Annualized Revenue by Chain"
            data={createChartData(revenueByChain, colorMap)}
            options={revenueOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="Annualized Revenue by Platform"
            data={createChartData(revenueByPlatform, colorMap)}
            options={revenueOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="CLM Users by Chain"
            data={createUsersByChainData()}
            options={usersOptions}
            isLoading={isLoading}
          />
          <ChartComponent
            title="CLM Users by Platform"
            data={createUsersByPlatformData()}
            options={usersOptions}
            isLoading={isLoading}
          />
        </>
      )}
      {isLoading && <div className="loading">Loading charts...</div>}
    </div>
  );
}

export default CLMsChartContainer;
