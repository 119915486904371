import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import './Chart.css';

function ChartComponent({ title, data, options, isLoading }) {
  const chartRef = useRef(null);
  const expandedChartRef = useRef(null);
  const chartInstance = useRef(null);
  const expandedChartInstance = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const createChart = useCallback(
    (canvas, isExpanded) => {
      if (!data || isLoading) return null;

      const ctx = canvas.getContext('2d');

      const chartConfig = {
        type: options.indexAxis === 'y' ? 'bar' : 'bar',
        data: data,
        options: {
          ...options,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            ...options.plugins,
            tooltip: {
              ...options.plugins.tooltip,
              callbacks: {
                ...options.plugins.tooltip.callbacks,
              },
            },
          },
          scales: {
            ...options.scales,
            x: {
              ...options.scales.x,
              ticks: {
                ...options.scales.x.ticks,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45,
              },
              y: {
                ticks: {
                  autoSkip: isExpanded ? false : true
                }
              }
            },
          },
        },
      };

      return new ChartJS(ctx, chartConfig);
    },
    [data, options, isLoading]
  );

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    if (chartRef.current) {
      chartInstance.current = createChart(chartRef.current, false);
    }

    const resizeObserver = new ResizeObserver(() => {
      if (chartInstance.current) {
        chartInstance.current.resize();
      }
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current.parentNode);
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      resizeObserver.disconnect();
    };
  }, [createChart]);

  useEffect(() => {
    if (isExpanded && expandedChartRef.current) {
      if (expandedChartInstance.current) {
        expandedChartInstance.current.destroy();
      }
      expandedChartInstance.current = createChart(expandedChartRef.current, true);
    }

    return () => {
      if (expandedChartInstance.current) {
        expandedChartInstance.current.destroy();
      }
    };
  }, [isExpanded, createChart]);

  const handleChartClick = () => {
    setIsExpanded(true);
  };

  const handleOverlayClick = e => {
    if (e.target.classList.contains('chart-overlay')) {
      setIsExpanded(false);
    }
  };

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  if (!data) {
    return <div className="error">No data available</div>;
  }

  return (
    <>
      <div className={`chart ${isExpanded ? 'expanded' : ''}`} onClick={handleChartClick}>
        <h2>{title}</h2>
        <div className="chart-wrapper">
          <canvas ref={chartRef}></canvas>
        </div>
      </div>
      {isExpanded && (
        <div className="chart-overlay" onClick={handleOverlayClick}>
          <div className="chart-expanded">
            <h2>{title}</h2>
            <div className="chart-wrapper">
              <canvas ref={expandedChartRef}></canvas>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChartComponent;
