import React from 'react';
import ChartComponent from '../Chart/Chart';
import {
  formatCurrency,
  formatNumber,
  chartColors,
  verticalChartOptions,
  horizontalChartOptions,
  isMobile, // Import the utility function
} from '../../utils/chartUtils';
import '../ChartContainer.css';

function VaultsChartContainer({
  vaultChainTVL,
  platformTVL,
  revenueByPlatform,
  revenueByChain,
  vaultsByPlatformAndNetwork,
  colorMap,
  isLoading,
  totalVaults,
  usersByChainAndType,
  usersByPlatformAndType,
}) {
  const createChartData = (data, colors, isCurrency = true, aggregateThreshold = 0) => {
    if (!data || typeof data !== 'object') {
      console.log('Invalid data for createChartData:', data);
      return { labels: [], datasets: [] };
    }

    let sortedEntries = Object.entries(data).sort((a, b) => b[1] - a[1]);

    if (aggregateThreshold > 0) {
      let otherSum = 0;
      sortedEntries = sortedEntries.reduce((acc, [key, value]) => {
        if (value < aggregateThreshold) {
          otherSum += value;
        } else {
          acc.push([key, value]);
        }
        return acc;
      }, []);

      if (otherSum > 0) {
        sortedEntries.push(['Other', otherSum]);
      }

      sortedEntries.sort((a, b) => b[1] - a[1]);
    }

    const sortedLabels = sortedEntries.map(
      ([key]) => key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
    );
    const sortedData = sortedEntries.map(([, value]) => value);

    return {
      labels: sortedLabels,
      datasets: [
        {
          label: isCurrency ? 'Value' : 'Count',
          data: sortedData,
          backgroundColor: sortedLabels.map(
            key => colors[key] || chartColors[sortedLabels.indexOf(key) % chartColors.length]
          ),
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderWidth: 1,
        },
      ],
    };
  };

  const baseOptions = {
    ...verticalChartOptions,
    indexAxis: 'x',
    scales: {
      x: {
        ...verticalChartOptions.scales.x,
        grid: {
          display: false,
        },
        ticks: {
          ...verticalChartOptions.scales.x.ticks,
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        ...verticalChartOptions.scales.y,
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
  };

  const vaultsOptions = isMobile() ? horizontalChartOptions : {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      y: {
        ...baseOptions.scales.y,
        ticks: {
          ...baseOptions.scales.y.ticks,
          autoSkip: false,
          callback: value => formatNumber(value),
        },
      },
    },
    plugins: {
      ...baseOptions.plugins,
      tooltip: {
        ...baseOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const vaultCount = context.parsed.y;
            return `${formatNumber(vaultCount)} Vaults`;
          },
        },
      },
    },
  };

  const tvlOptions = isMobile() ? horizontalChartOptions : {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      x: {
        ...baseOptions.scales.x,
        ticks: {
          ...baseOptions.scales.x.ticks,
          font: function (context) {
            const width = context.chart.width;
            if (width < 400) {
              return { size: 7 };
            } else if (width < 600) {
              return { size: 9 };
            } else {
              return { size: 10 };
            }
          },
        },
      },
      y: {
        ...baseOptions.scales.y,
        ticks: {
          ...baseOptions.scales.y.ticks,
          autoSkip: false,
          callback: value => formatCurrency(value),
        },
      },
    },
    plugins: {
      ...baseOptions.plugins,
      tooltip: {
        ...baseOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const value = context.parsed.y;
            return `${formatCurrency(value)}`;
          },
        },
      },
    },
  };

  const platformTVLOptions = { ...tvlOptions };
  const revenueOptions = { ...tvlOptions };

  const usersOptions = isMobile() ? horizontalChartOptions : {
    ...baseOptions,
    scales: {
      ...baseOptions.scales,
      y: {
        ...baseOptions.scales.y,
        ticks: {
          ...baseOptions.scales.y.ticks,
          callback: value => formatNumber(value),
        },
      },
    },
    plugins: {
      ...baseOptions.plugins,
      tooltip: {
        ...baseOptions.plugins.tooltip,
        callbacks: {
          label: context => {
            const userCount = context.parsed.y;
            return `${formatNumber(userCount)} Users`;
          },
        },
      },
    },
  };

  const createUsersByChainData = () => {
    if (!usersByChainAndType) return { labels: [], datasets: [] };
    const vaultUsers = {};
    Object.entries(usersByChainAndType).forEach(([chain, types]) => {
      vaultUsers[chain] = types.vault || 0;
    });
    return createChartData(vaultUsers, colorMap, false, 1000);
  };

  const createUsersByPlatformData = () => {
    if (!usersByPlatformAndType) return { labels: [], datasets: [] };
    const vaultUsers = {};
    Object.entries(usersByPlatformAndType).forEach(([platform, types]) => {
      vaultUsers[platform] = types.vault || 0;
    });
    return createChartData(vaultUsers, colorMap, false, 1000);
  };

  return (
    <div className="charts-container">
      <ChartComponent
        title="Vaults per Chain"
        data={createChartData(vaultsByPlatformAndNetwork.byNetwork, colorMap, false)}
        options={vaultsOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="TVL per Chain"
        data={createChartData(vaultChainTVL, colorMap, true, 105000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="TVL per Platform"
        data={createChartData(platformTVL, colorMap, true, 800000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Annualized Revenue by Chain"
        data={createChartData(revenueByChain, colorMap, true, 5000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Annualized Revenue by Platform"
        data={createChartData(revenueByPlatform, colorMap, true, 10000)}
        options={tvlOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Vault Users by Chain"
        data={createUsersByChainData()}
        options={usersOptions}
        isLoading={isLoading}
      />
      <ChartComponent
        title="Vault Users by Platform"
        data={createUsersByPlatformData()}
        options={usersOptions}
        isLoading={isLoading}
      />
    </div>
  );
}

export default VaultsChartContainer;
