import React from 'react';
import beefyLogo from '../../../src/images/header-logo.svg'; // Make sure this path is correct
import './DashboardHeader.css';

function DashboardHeader() {
  return (
    <header className="dashboard-header">
      <div className="header-content">
        <div className="logo-title-container">
          <a
            href="https://app.beefy.com"
            target="_blank"
            rel="noopener noreferrer"
            className="logo-link"
          >
            <img src={beefyLogo} alt="Beefy Logo" className="header-logo" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default DashboardHeader;
