import React from 'react';
import { formatCurrency, formatNumber } from '../../utils/chartUtils';
import './DashboardMetrics.css';

function DashboardMetrics({
  page,
  totalTVL,
  vaultChainTVL,
  activeCLMs,
  supportedChains,
  supportedPlatforms,
  supportedVaultChains,
  supportedVaultPlatforms,
  totalRevenue,
  totalClmRevenue,
  totalVaults,
  totalChains,
  totalPlatforms,
  totalUsers,
  totalUsersByType,
}) {
  const dailyRevenue = totalRevenue / 365;
  const totalVaultsAndCLMs = totalVaults + activeCLMs;
  const totalTvl = totalTVL + vaultChainTVL;
  const overallRevenue = totalClmRevenue + totalRevenue;
  const dailyCLMRevenue = totalClmRevenue / 365;
  const overallDailyRevenue = overallRevenue / 365;

  const metrics = {
    overview: [
      { title: 'Total TVL', value: formatCurrency(totalTvl) },
      { title: 'Total Vaults and CLMs', value: formatNumber(totalVaultsAndCLMs) },
      { title: 'Annualized Revenue', value: formatCurrency(overallRevenue) },
      { title: 'Daily Revenue', value: formatCurrency(overallDailyRevenue) },
      { title: 'Supported Chains', value: formatNumber(totalChains) },
      { title: 'Supported Platforms', value: formatNumber(totalPlatforms) },
      { title: 'Total Users', value: formatNumber(totalUsers) },
    ],
    clms: [
      { title: 'CLM TVL', value: formatCurrency(totalTVL) },
      { title: 'Total CLMs', value: formatNumber(activeCLMs) },
      { title: 'CLM Annualized Revenue', value: formatCurrency(totalClmRevenue) },
      { title: 'CLM Daily Revenue', value: formatCurrency(dailyCLMRevenue) },
      { title: 'Supported Chains', value: formatNumber(supportedChains) },
      { title: 'Supported Platforms', value: formatNumber(supportedPlatforms) },
      { title: 'Total Users', value: formatNumber(totalUsersByType.clm) },
    ],
    vaults: [
      { title: 'Vault TVL', value: formatCurrency(vaultChainTVL) },
      { title: 'Total Vaults', value: formatNumber(totalVaults) },
      { title: 'Vault Annualized Revenue', value: formatCurrency(totalRevenue) },
      { title: 'Vault Daily Revenue', value: formatCurrency(dailyRevenue) },
      { title: 'Supported Chains', value: formatNumber(supportedVaultChains) },
      { title: 'Supported Platforms', value: formatNumber(supportedVaultPlatforms) },
      { title: 'Total Users', value: formatNumber(totalUsersByType.vault) },
    ],
  };

  const pageMetrics = metrics[page] || metrics.overview;

  return (
    <div className="dashboard-metrics">
      {pageMetrics.map((metric, index) => (
        <MetricCard key={index} title={metric.title} value={metric.value} />
      ))}
    </div>
  );
}

function MetricCard({ title, value }) {
  return (
    <div className="metric-card">
      <h3>{title}</h3>
      <p className="metric-value">{value}</p>
    </div>
  );
}

export default DashboardMetrics;
