import React from 'react';
import { Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import DashboardHeader from './components/DashboardHeader/DashboardHeader';

function App() {
  return (
    <div className="App">
      <DashboardHeader />
      <nav>
        <ul>
          <li>
            <NavLink to="/" end>
              Overview
            </NavLink>
          </li>
          <li>
            <NavLink to="/clms">CLMs</NavLink>
          </li>
          <li>
            <NavLink to="/vaults">Vaults</NavLink>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<Dashboard page="overview" />} />
        <Route path="/clms" element={<Dashboard page="clms" />} />
        <Route path="/vaults" element={<Dashboard page="vaults" />} />
      </Routes>
    </div>
  );
}

export default App;
