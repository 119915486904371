import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { almPlatforms } from '../data/almPlatforms';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const chartColors = [
  '#FF6B6B',
  '#4ECDC4',
  '#FFA500',
  '#45B7D1',
  '#98D8C8',
  '#F3A712',
  '#5D5FEF',
  '#FF8C42',
  '#A3A1FF',
  '#3BCEAC',
  '#FF69B4',
  '#20B2AA',
  '#BA55D3',
  '#32CD32',
  '#FF4500',
  '#1E90FF',
  '#FFD700',
  '#8A2BE2',
  '#00CED1',
  '#FF6347',
];

export function createColorMap(clmData, chainTVL, platformTVL, almTVLData) {
  const colorMap = {};
  const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    '#9966FF',
    '#FF9F40',
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#4BC0C0',
    '#9966FF',
    '#FF9F40',
  ];

  let colorIndex = 0;

  // Assign colors to chains
  Object.keys(clmData || {}).forEach(chain => {
    colorMap[chain] = colors[colorIndex % colors.length];
    colorIndex++;
  });

  // Assign colors to platforms
  Object.keys(platformTVL || {}).forEach(platform => {
    if (!colorMap[platform]) {
      colorMap[platform] = colors[colorIndex % colors.length];
      colorIndex++;
    }
  });

  // Assign colors to ALM platforms
  Object.values(almPlatforms).forEach(platform => {
    if (!colorMap[platform.label]) {
      colorMap[platform.label] = colors[colorIndex % colors.length];
      colorIndex++;
    }
  });

  return colorMap;
}

export function createChartData(data, colorMap, sortDescending = true) {
  const sortedEntries = Object.entries(data).sort((a, b) =>
    sortDescending ? b[1] - a[1] : a[1] - b[1]
  );

  const labels = sortedEntries.map(([label]) => label);
  const values = sortedEntries.map(([, value]) => value);
  const backgroundColor = labels.map(label => colorMap[label] || '#000000');

  return {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor,
      },
    ],
  };
}

export function formatCurrency(value) {
  // console.log('Formatting currency value:', value);
  if (typeof value !== 'number' || isNaN(value)) {
    // console.warn('Invalid currency value:', value);
    return '$0.00';
  }
  if (value >= 1e9) {
    return `$${(value / 1e9).toFixed(2)}B`;
  } else if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(2)}M`;
  } else if (value >= 1e3) {
    return `$${(value / 1e3).toFixed(2)}K`;
  } else {
    return `$${value.toFixed(2)}`;
  }
}

export function formatNumber(value) {
  if (typeof value !== 'number') {
    return value;
  }
  if (value >= 1e9) {
    return `${(value / 1e9).toFixed(2)}B`;
  } else if (value >= 1e6) {
    return `${(value / 1e6).toFixed(2)}M`;
  } else if (value >= 1e3) {
    return `${(value / 1e3).toFixed(2)}K`;
  } else {
    return value.toFixed(0);
  }
}

const baseChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#1e4976',
      titleColor: '#ffffff',
      bodyColor: '#ffffff',
      titleFont: {
        family: 'Poppins',
        size: 14,
        weight: '600',
      },
      bodyFont: {
        family: 'Poppins',
        size: 12,
        weight: '400',
      },
      borderColor: '#5090d3',
      borderWidth: 1,
      // Remove the callbacks from here
    },
  },
  scales: {
    x: {
      grid: {
        color: 'rgba(80, 144, 211, 0.1)',
      },
      ticks: {
        color: '#b2bac2',
        font: function (context) {
          if (context.tick && context.tick.label === almPlatforms.beefy.label) {
            return {
              family: 'Poppins',
              size: 11,
              weight: 800,
            };
          }
          return {
            family: 'Poppins',
            size: 9,
            weight: 'normal',
          };
        },
      },
    },
    y: {
      grid: {
        color: 'rgba(80, 144, 211, 0.1)',
      },
      ticks: {
        color: '#b2bac2',
        font: function (context) {
          if (context.tick && context.tick.label === almPlatforms.beefy.label) {
            return {
              family: 'Poppins',
              size: 12,
              weight: 800,
            };
          }
          return {
            family: 'Poppins',
            size: 12,
            weight: 'normal',
          };
        },
      },
    },
  },
};

export const verticalChartOptions = {
  ...baseChartOptions,
  indexAxis: 'x',
  scales: {
    ...baseChartOptions.scales,
    x: {
      ...baseChartOptions.scales.x,
      ticks: {
        ...baseChartOptions.scales.x.ticks,
        maxRotation: 45,
        minRotation: 45,
      },
      grid: {
        display: false,
      },
    },
  },
  isCurrency: true, // Adjust this based on your data
};

export const horizontalChartOptions = {
  ...baseChartOptions,
  indexAxis: 'y',
  scales: {
    x: {
      ...baseChartOptions.scales.x,
      grid: {
        display: false, // Remove grid lines on the x-axis
      },
      ticks: {
        ...baseChartOptions.scales.x.ticks,
        callback: function (value) {
          return formatCurrency(value); // Adjust for currency charts
        },
      },
    },
    y: {
      ...baseChartOptions.scales.y,
      grid: {
        display: false, // Remove grid lines on the y-axis
      },
      ticks: {
        ...baseChartOptions.scales.y.ticks,
        callback: function (value, index) {
          return this.chart.data.labels[value]; // Return the original label
        },
      },
    },
  },
};

export function formatTVL(value) {
  if (value >= 1e9) return (value / 1e9).toFixed(2) + 'B';
  if (value >= 1e6) return (value / 1e6).toFixed(2) + 'M';
  if (value >= 1e3) return (value / 1e3).toFixed(2) + 'K';
  return value.toFixed(2);
}

export function formatTVLCompact(value) {
  if (value >= 1e9) return `$${(value / 1e9).toFixed(1)}B`;
  if (value >= 1e6) return `$${(value / 1e6).toFixed(1)}M`;
  if (value >= 1e3) return `$${(value / 1e3).toFixed(1)}K`;
  return `$${value.toFixed(0)}`;
}

export function isMobile() {
  return window.innerWidth <= 768;
}
