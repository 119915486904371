export const almPlatforms = {
  beefy: {
    key: 'beefy',
    label: 'Beefy',
    apiEndpoint: null, // Beefy data is fetched differently
  },
  gamma: {
    key: 'gamma',
    label: 'Gamma',
    apiEndpoint: 'https://api.llama.fi/tvl/gamma',
  },
  steer: {
    key: 'steer-protocol',
    label: 'Steer',
    apiEndpoint: 'https://api.llama.fi/tvl/steer-protocol',
  },
  arrakis: {
    key: 'arrakis-finance',
    label: 'Arrakis',
    apiEndpoint: 'https://api.llama.fi/tvl/arrakis-finance',
  },
  ichi: {
    key: 'ichi',
    label: 'Ichi',
    apiEndpoint: 'https://api.llama.fi/tvl/ichi',
  },
  defiedge: {
    key: 'defiedge',
    label: 'DefiEdge',
    apiEndpoint: 'https://api.llama.fi/tvl/defiedge',
  },
};
